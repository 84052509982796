import Vue from "vue";
import VueRouter from "vue-router";
import home from '@/view/home/home'
import store from '@/store'
Vue.use(VueRouter);
const routes = [
    {
        path: "/",
        component: home,
        meta: {
            title: "ML格调-一个专注于技术分享的博客平台"
        },
        children: [
            {
                path: "/",
                component: resolve => require(["@/view/home/index.vue"], resolve),
                meta: {
                    title: "ML格调-一个专注于技术分享的博客平台"
                }
            },
            {
                path: "/article/:articleId",
                component: resolve => require(["@/view/article/index.vue"], resolve),
            },
            
            {
                path: "/archive",
                component: resolve => require(["@/view/archive/index"], resolve),
                meta: {
                    title: "文章归档"
                }
            },
            {
                path: "/category",
                component: resolve => require(["@/view/category/index.vue"], resolve),
                meta: {
                    title: "文章分类"
                }
            },
            {
                path: "/tags",
                component: resolve => require(["@/view/tag/index"], resolve),
                meta: {
                    title: "文章标签"
                }
            }

        ],
    },
    {
        path: "*",
        name: "NotFound",
        component: () => import("@/view/404/404.vue"),
    }
];

const router = new VueRouter({
    //mode: "history",
    scrollBehavior: () => ({ y: 0 }),
    routes
});
// 获取原型对象push函数
const originalPush = VueRouter.prototype.push

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch(err => err)
}

router.beforeEach((to, from, next) => {
    store.state.searchDialogVisible = false;
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})
export default router;